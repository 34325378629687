<template lang="pug">
.paginated-list
  RemotePaginatedList(
    :name="name",
    :search="search",
    :itemsPerPage="itemsPerPage",
    :breakpoints="breakpoints",
    v-slot="{ items }"
  )
    slot(:items="items")

</template>
<script setup lang="ts">
import type { Search, SearchResult } from 'index'

interface Breakpoint {
  itemsPerPage: number
}

const props = defineProps({
  name: String,
  items: {
    type: Array as PropType<any[]>,
    required: true
  },
  breakpoints: {
    type: Object as PropType<{ [key: number]: Breakpoint }>,
  },
  itemsPerPage: {
    type: Number,
    default: 10
  }
})

const search = ref(async (search: Search): Promise<SearchResult<any>> => {
  const pageSize = (search.pageSize ?? props.itemsPerPage)
  const start = (search.page ?? 0) * pageSize
  const end = start + pageSize

  const currentItems = props.items.slice(start, end)

  return {
    search,
    results: props.items.slice(start, end),
    count: currentItems.length,
    totalCount: props.items.length
  }
})
</script>
